import BackButton from '@luna-protocol/core/src/components/BackButton/BackButton';
import Banner from '@luna-protocol/core/src/components/Banner/Banner';
import Body from '@luna-protocol/core/src/components/Body/Body';
import Button from '@luna-protocol/core/src/components/Button/Button';
import ButtonGroup from '@luna-protocol/core/src/components/ButtonGroup/ButtonGroup';
import Card from '@luna-protocol/core/src/components/Card/Card';
import CustomerName from '@luna-protocol/core/src/components/CustomerName/CustomerName';
import LineBreak from '@luna-protocol/core/src/components/LineBreak/LineBreak';
import MiniDataTable from '@luna-protocol/core/src/components/MiniDataTable/MiniDataTable.tsx';
import SelectInput from '@luna-protocol/core/src/components/SelectInput/SelectInput';
import { Spinner } from '@luna-protocol/core/src/components/Spinner/Spinner.tsx';
import { USDollar } from '@luna-protocol/core/src/utils/constants/format.ts';
import { useAuth } from '@luna-protocol/core/src/utils/useAuth.ts';
import { CellContext, ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { NavLink, useNavigate } from 'react-router-dom';
import { AppContext } from '../../AppContext.tsx';
import { useGetProgramTerms } from '../../queries/useGetProgramTerms.ts';
import { useGetPrograms } from '../../queries/useGetPrograms.ts';
import { useGetSerialNumbers } from '../../queries/useGetSerialNumbers.ts';
import { ProgramRequest, usePostSelectProgram } from '../../queries/usePostSelectProgram.ts';
import ProductSummary from '../ProductSummary/ProductSummary.tsx';
import Totals from '../Totals/Totals.tsx';
import messages from './ProgramSelect.messages.ts';
import './ProgramSelect.scss';
import config from '@luna-protocol/core/src/utils/feature-flags.json';

export interface ProgramProps {
  program_id: string;
  apr: number;
  coupon: number;
  participation: number;
  dealer_fee: number;
  tenor: number;
  initial_monthly_payment: number;
  monthly_payment: number;
  promo_duration: number;
  program_code: string;
  program_type_code: string;
  deferred: boolean;
  promo_coupon: number;
  number_of_payments: number;
}

const ProgramSelect = () => {
  useAuth();
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const {
    loanApplicationID,
    selectedProgram,
    setSelectedProgram,
    customerName,
    OEMType,
    dealerFlowTemplate,
    setSerialNumbers,
  } = useContext(AppContext);
  const [currentFilterValue, setCurrentFilterValue] = useState<string>('');
  const { data: programData, isLoading: programsLoading } = useGetPrograms({
    loanApplicationID: loanApplicationID || '',
    term: (currentFilterValue || '').split(' ')[0],
  });

  const { data: termData, isLoading: termLoading } = useGetProgramTerms(loanApplicationID || '');
  const columnHelper = createColumnHelper<ProgramProps>();
  const { selectProgram } = usePostSelectProgram();
  const { data: serialNumbersData } = useGetSerialNumbers(loanApplicationID, data => {
    setSerialNumbers(data);
  });

  const [data, setData] = useState<ProgramProps[]>([]);

  const createOptions = useMemo(() => {
    if (termData && termData.length > 0) {
      const options = [...new Set(termData?.map(term => `${term} months`))].sort((a, b) => {
        const aNum = parseInt(a.split(' ')[0]);
        const bNum = parseInt(b.split(' ')[0]);
        return bNum - aNum;
      });
      return options;
    }
    return [];
  }, [termData]);

  const filterOptions = useCallback(
    (value: string) => {
      setCurrentFilterValue(value);
      if (programData && programData.length > 0) {
        const selected = parseInt(value.split(' ')[0]);
        const filteredData = programData?.filter(program => program.tenor === selected);
        // programs are sorted Participation ASC, but non-standard programs are shown first
        const promoPrograms = filteredData
          ?.filter(program => program.program_type_code !== 'STANDARD')
          .sort((a, b) => {
            return a.monthly_payment - b.monthly_payment;
          });
        const nonPromoPrograms = filteredData
          ?.filter(program => program.program_type_code === 'STANDARD')
          .sort((a, b) => {
            return a.monthly_payment - b.monthly_payment;
          });
        const sortedData = [...promoPrograms, ...nonPromoPrograms];
        setData(sortedData);
      }
      return;
    },
    [programData],
  );

  useEffect(() => {
    if (!currentFilterValue) {
      filterOptions(createOptions[0]);
    } else {
      filterOptions(currentFilterValue);
    }
  }, [programData, termData, currentFilterValue, createOptions, filterOptions]);

  const getPromoName = (info: CellContext<ProgramProps, string>) => {
    const programCode = info.row.original.program_code;
    const isDeferred = info.row.original.deferred;
    const duration = info.row.original.promo_duration;

    switch (info.getValue()) {
      case 'LLBUYDOWN':
        return formatMessage(messages.llbuydownLabel, {
          buydown_rate: ((info.row.original.coupon - info.row.original.promo_coupon) * 100).toFixed(2),
        });
      case 'BUYDOWN':
        return formatMessage(messages.buydownLabel, {
          duration: duration,
          buydown_rate: ((info.row.original.coupon - info.row.original.promo_coupon) * 100).toFixed(2),
        });
      case 'EQUAL':
        return formatMessage(messages.equalLabel);
      case 'LOW':
        return formatMessage(messages.lowLabel);
      case 'PROMO':
        if (programCode.startsWith('NOINT') && isDeferred) {
          return formatMessage(messages.noInterestDeferredLabel, {
            duration: duration,
          });
        } else if (programCode.startsWith('NOINT') && !isDeferred) {
          return formatMessage(messages.noInterestLabel, {
            duration: duration,
          });
        } else if (programCode.startsWith('SAC') && isDeferred) {
          return formatMessage(messages.sacDeferredLabel, {
            duration: duration,
          });
        } else if (programCode.startsWith('SAC') && !isDeferred) {
          return formatMessage(messages.sacLabel, {
            duration: duration,
          });
        } else if (programCode.startsWith('STEPUP')) {
          return formatMessage(messages.stepUpLabel, {
            duration: duration,
          });
        } else if (programCode.startsWith('DEF')) {
          return formatMessage(messages.defPaymentLabel, {
            duration: duration,
          });
        }
        return formatMessage(messages.promoLabel, {
          duration: duration,
        });
      default:
        return formatMessage(messages.standardLabel);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const columns: ColumnDef<ProgramProps, any>[] = [];

  if (!config.FF_OEMTemplatesEnabled) {
    columns.push(
      columnHelper.accessor('participation', {
        cell: info => `${info.getValue().toFixed(2)}%`,
        header: formatMessage(messages.participationLabel),
      }),
    );
  }

  if (OEMType === 'ezgo' && !config.FF_OEMTemplatesEnabled) {
    columns.push(
      columnHelper.accessor('dealer_fee', {
        cell: info => `${info.getValue().toFixed(2)}%`,
        header: formatMessage(messages.dealerFeeLabel),
      }),
    );
  }

  if (config.FF_OEMTemplatesEnabled) {
    if (dealerFlowTemplate?.participation_fee) {
      columns.push(
        columnHelper.accessor('participation', {
          cell: info => `${info.getValue().toFixed(2)}%`,
          header: formatMessage(messages.participationLabel),
        }),
      );
    }
    if (dealerFlowTemplate?.dealer_fee) {
      columns.push(
        columnHelper.accessor('dealer_fee', {
          cell: info => `${info.getValue().toFixed(2)}%`,
          header: formatMessage(messages.dealerFeeLabel),
        }),
      );
    }
  }
  columns.push(
    columnHelper.accessor('coupon', {
      cell: info => {
        // if there is a promo show the promo rate (i.e. 7.99%/9.99%)
        // but only if the promo rate is different than the standard rate to avoid showing 9.99%/9.99%
        if (info.row.original.program_type_code === 'LLBUYDOWN') {
          return `${(info.row.original.promo_coupon * 100).toFixed(2)}%`;
        }
        if (info.row.original.promo_coupon && info.row.original.promo_coupon !== info.getValue()) {
          return `${(info.row.original.promo_coupon * 100).toFixed(2)}%/${(info.getValue() * 100).toFixed(2)}%`;
        }
        // if there is no promo show the standard rate (i.e. 9.99%)
        return `${(info.getValue() * 100).toFixed(2)}%`;
      },
      header: formatMessage(messages.interestRateLabel),
    }),
    columnHelper.accessor('tenor', {
      cell: info => `${info.getValue()} months`,
      header: formatMessage(messages.termLabel),
    }),
    columnHelper.accessor('monthly_payment', {
      cell: info => {
        // if there is a different initial payment show the promo payment (i.e. $100/$150)
        if (info.row.original.initial_monthly_payment) {
          return `${USDollar.format(info.row.original.initial_monthly_payment)}/${USDollar.format(info.getValue())}`;
        }
        // if there is no promo show the standard payment (i.e. $150)
        return `${USDollar.format(info.getValue())}`;
      },
      header: formatMessage(messages.monthlyPaymentLabel),
    }),
    columnHelper.accessor('program_type_code', {
      cell: info => `${getPromoName(info)}`,
      header: formatMessage(messages.descriptionLabel),
    }),
  );

  const submitOption = () => {
    const programSelect: ProgramRequest = {
      loan_application_id: loanApplicationID as string,
      loan_program_id: selectedProgram?.program_id as string,
    };

    selectProgram(programSelect, {
      onSuccess: () => {
        navigate('/review_deal');
      },
      onError: context => {
        console.log(context);
      },
    });
  };

  //const productPriceTotal = useMemo(() => {
  //return serialNumbersData?.serial_numbers?.reduce((acc: number, curr: SerialNumber) => {
  //return acc + (curr.product_price || 0);
  //}, 0);
  //}, [serialNumbersData?.serial_numbers]);

  return (
    <>
      <BackButton path="/add_products" inverted />
      <Banner alternate>{formatMessage(messages.title)}</Banner>
      <CustomerName applicantName={customerName || ''} />
      <Body fullWidth stretched>
        {!termLoading ? (
          <>
            <div className="add-products--container">
              <div className="inner-container left">
                <>
                  <Card>
                    <Totals />
                  </Card>
                </>
                <LineBreak largeMargin />
                <>
                  <SelectInput
                    name="program"
                    formik={false}
                    label={formatMessage(messages.filterLabel)}
                    value={currentFilterValue}
                    options={createOptions}
                    onChange={e => filterOptions((e as string) || '')}
                    disabled={termLoading}
                    isClearable={false}
                  />
                </>
              </div>
              <div className="inner-container right">
                <Card variant="grey">
                  <ProductSummary
                    editable={false}
                    approvedAmount={serialNumbersData?.approved_amount}
                    showConfirmButton={false}
                    showFinancing={false}
                  />
                </Card>
              </div>
            </div>
            <div className="table-container">
              <MiniDataTable
                index={0}
                data={data}
                promotedRanges={data.reduce((indexes: number[], item, index) => {
                  if (item.program_type_code === 'PROMO') {
                    indexes.push(index);
                  }
                  return indexes;
                }, [])}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                columns={columns as ColumnDef<unknown, any>[]}
                onRowClick={row => {
                  setSelectedProgram(row);
                }}
                isLoading={programsLoading}
              />
              <LineBreak largeMargin />
              <ButtonGroup align="left">
                <NavLink to={'/prequalification'}>
                  <Button testId={'save'}>{formatMessage(messages.saveButtonLabel)}</Button>
                </NavLink>
                <Button
                  fullWidth={false}
                  variant="secondary"
                  onClick={submitOption}
                  name={'select'}
                  disabled={!selectedProgram}>
                  {formatMessage(messages.selectButtonLabel)}
                </Button>
              </ButtonGroup>
            </div>
          </>
        ) : (
          <Spinner color="black" />
        )}
      </Body>
    </>
  );
};

export default ProgramSelect;
