import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'ProgramSelect.title',
    defaultMessage: 'View Finance Offers',
  },
  filterLabel: {
    id: 'ProgramSelect.filterLabel',
    defaultMessage: 'Select financing term',
  },
  participationLabel: {
    id: 'ProgramSelect.participationLabel',
    defaultMessage: 'Participation',
  },
  dealerFeeLabel: {
    id: 'ProgramSelect.dealerFeeLabel',
    defaultMessage: 'Dealer Fee',
  },
  interestRateLabel: {
    id: 'ProgramSelect.interestRateLabel',
    defaultMessage: 'Interest Rate',
  },
  termLabel: {
    id: 'ProgramSelect.termLabel',
    defaultMessage: 'Term',
  },
  monthlyPaymentLabel: {
    id: 'ProgramSelect.monthlyPaymentLabel',
    defaultMessage: 'Monthly Payment',
  },
  descriptionLabel: {
    id: 'ProgramSelect.descriptionLabel',
    defaultMessage: 'Description',
  },
  selectButtonLabel: {
    id: 'ProgramSelect.selectButtonLabel',
    defaultMessage: 'Select option and proceed',
  },
  saveButtonLabel: {
    id: 'ProgramSelect.saveButton',
    defaultMessage: 'Save and Return',
  },
  buydownLabel: {
    id: 'ProgramSelect.buydownLabel',
    defaultMessage: '{duration} Months {buydown_rate}% Buydown',
  },
  equalLabel: {
    id: 'ProgramSelect.equalLabel',
    defaultMessage: 'Equal',
  },
  lowLabel: {
    id: 'ProgramSelect.lowLabel',
    defaultMessage: 'Low',
  },
  noInterestDeferredLabel: {
    id: 'ProgramSelect.noInterestDeferredLabel',
    defaultMessage: '{duration} Months No Interest No Payment',
  },
  noInterestLabel: {
    id: 'ProgramSelect.noInterestLabel',
    defaultMessage: '{duration} Months No Interest With Payment',
  },
  sacDeferredLabel: {
    id: 'ProgramSelect.sacDeferredLabel',
    defaultMessage: '{duration} Months Deferred Interest No Payments',
  },
  sacLabel: {
    id: 'ProgramSelect.sacLabel',
    defaultMessage: '{duration} Months Deferred Interest with Payment',
  },
  defPaymentLabel: {
    id: 'ProgramSelect.defPaymentLabel',
    defaultMessage: '{duration} Months Deferred Payment',
  },
  stepUpLabel: {
    id: 'ProgramSelect.stepUpLabel',
    defaultMessage: '{duration} Months Step-Up Program',
  },
  promoLabel: {
    id: 'ProgramSelect.promoLabel',
    defaultMessage: '{duration} Months Promotion',
  },
  standardLabel: {
    id: 'ProgramSelect.standardLabel',
    defaultMessage: 'Standard',
  },
  llbuydownLabel: {
    id: 'ProgramSelect.llbuydownLabel',
    defaultMessage: '{buydown_rate}% Buydown',
  },
});

export default messages;
